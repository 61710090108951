<template>
  <div>
    <section>
      <div class="level header">
        <img  @click="$router.push('/')" class="level-left" src="./../assets/img/home/logo.png" />
        <div class="page-subtitle level-right">
          Rejestracja klienta indywidualnego
        </div>
      </div>
    </section>

    <section class="block">
      <client-registration-form></client-registration-form>
    </section>
    <section>
      <page-footer></page-footer>
    </section>
  </div>
</template>

<script>
import ClientRegistrationForm from "@/components/auth/ClientRegistrationForm";
import Footer from "@/components/common/Footer";

export default {
  components: {
    "client-registration-form": ClientRegistrationForm,
    "page-footer": Footer,
  },
};
</script>

<style lang="scss" >
.header {
  width: 100%;
  height: 10rem;
  background-color: #000;
}

.page-subtitle {
  position: relative;
  right: 2rem;
  color: $primary;
  font-weight: 700;
  font-size: 24pt;
}
</style>